.two {
  color: white;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  display: flex;
}

.about1,
.about2 {
  position: relative;
  width: 50%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about1 {
  padding-left: 15%;
  padding-right: 5%;
}

.about2 {
  padding-left: 5%;
  padding-right: 15%;
}

.hello {
  font-family: Consolas, courier-std, monospace !important;
  font-size: 5rem;
  color: var(--c1);
}

.me {
  font-size: 1.2rem;
  text-align: justify;
  line-height: 1.7rem;
}

.more {
  display: none;
}

.edu,
.skills-wrapper {
  width: 100%;
  margin: 1rem;
}

.edu-title,
.skills-title {
  font-weight: 700;
  font-size: 3rem;
  margin: 1rem 0rem;
}

.edu div {
  display: flex;
  align-items: center;
}

.edu span {
  font-size: 1.2rem;
  cursor: pointer;
  margin: 0rem 1rem;
  line-height: 2rem;
}

.edu img {
  height: 1.5rem;
}

.skills {
  width: 100%;
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--bg);
  margin: 1rem 0rem;
  text-align: justify;
  display: flex;
  flex-wrap: wrap;
}

.skills span {
  display: inline-block;
  transition: all 0.2s;
  margin: 0.5rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  cursor: none;
}

.logo {
  display: none;
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
  width: 4rem;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.5, 0.5) rotate(0deg);
  }
  40% {
    transform: translate(-50%, -50%) scale(1, 1) rotate(360deg);
  }
  60% {
    transform: translate(-50%, -50%) scale(1, 1) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.5, 0.5) rotate(0deg);
  }
}
