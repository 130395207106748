@media only screen and (max-width: 600px) {
  html {
    font-size: 9px !important;
  }

  .doggo,
  .doggo-div {
    display: none;
  }

  .scroll-container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    width: 100vw;
    scroll-behavior: smooth;
  }

  .two,
  .three,
  .four {
    position: relative;
    left: 0;
  }

  .header {
    flex-direction: column;
  }

  .sec1 {
    width: 100%;
  }

  .sec2 {
    display: none;
  }

  .name {
    text-align: center;
  }

  .scroll-animation div {
    display: none;
  }

  .scroll-animation {
    top: 80%;
    right: 50%;
    transform: translate(50%, 0%) rotate(90deg);
  }

  @keyframes spin {
    100% {
      transform: translate(50%, 50%) rotate(90deg);
      opacity: 1;
    }
  }

  .two {
    flex-direction: column;
  }

  .about1,
  .about2 {
    width: 100%;
    padding: 0rem 4rem;
    margin: 0;
  }

  .about1 {
    justify-content: flex-end;
  }

  .about2 {
    justify-content: flex-start;
  }

  .about2 > * {
    margin: 0;
  }

  .three {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-wrapper {
    position: absolute;
    height: 100vw;
    transform: rotate(90deg);
    width: 90vh;
    padding: 4rem;
  }

  .project {
    height: 100%;
  }

  .project-description {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }

  .four {
    overflow-x: hidden;
  }

  .contact-wrapper {
    position: absolute;
    flex-direction: column;
    width: 100%;
    padding: 0rem 4rem;
    margin: 0;
    height: 90%;
    bottom: 0;
  }

  .contact1,
  .contact2 {
    width: 100%;
    margin: 0%;
    padding: 0;
    align-items: center;
  }

  .contact1 {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    height: 50%;
  }

  .contact1 > * {
    height: fit-content;
  }

  .contact2 {
    padding-bottom: 5rem;
  }

  .contact2 > * {
    width: 100%;
  }

  .GIT {
    top: 40%;
  }

  #git1 {
    transform: translate(-115%, 0%);
    animation: slide 5s linear infinite;
  }

  #git2 {
    transform: translate(0%, 0%);
    animation: slide 5s linear infinite;
  }

  .pr-row1 {
    flex-direction: column;
  }

  .pr-col1,
  .pr-col2 {
    width: 100% !important;
  }

  .pr-title {
    font-size: 4rem !important;
  }

  .pr-row2 {
    column-count: 1 !important;
    font-size: 1.5rem !important;
  }

  .gallery > div {
    width: 100% !important;
  }

  @keyframes slide {
    0% {
      right: -100%;
    }

    100% {
      right: 100%;
    }
  }

  @media only screen and (max-height: 660px) {
    .about1 {
      height: 45%;
    }

    .about2 {
      z-index: -1;
    }

    .text-wrapper {
      position: relative;
      height: 75%;
      overflow-y: hidden;
    }

    .hello {
      font-size: 3rem;
    }

    .me {
      background-color: #141414;
    }

    .more {
      width: 100%;
      font-size: 1.5rem;
      display: flex;
      position: absolute;
      bottom: 0;
      color: rgb(97, 97, 97);
      background-color: #141414;
    }

    .skills-title,
    .edu-title {
      font-size: 2rem;
    }

    .skills span {
      font-size: 1.5rem;
      -webkit-text-stroke-width: inherit;
      -webkit-text-fill-color: inherit;
      color: white;
    }

    .contact1,
    .contact2 {
      font-size: 2rem;
    }

    .contact-links {
      font-size: 1.7rem;
    }

    .links {
      font-size: 1.7rem;
    }
    .GIT {
      font-size: 4rem;
    }
  }
}
