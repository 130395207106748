@import url('https://use.typekit.net/gki0kgr.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  --bg: #141414;
  --c1: #00ffcc;
  --c2: #ff5454;
  background: var(--bg);
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
}

.scroll-container {
  position: relative;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  height: 100vh;
  scroll-behavior: smooth;
}

section {
  height: 100%;
  scroll-snap-align: start end;
}

.header {
  width: 100%;
  height: 10%;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  position: fixed;
  top: 0;
  z-index: 10;
}

.sec1 {
  height: 100%;
  width: 30%;
  display: flex;
  padding: 0 3rem;
  justify-content: space-between;
  align-items: center;
}

.sec1 a {
  position: relative;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.sec1 > a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: white;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.2s linear;
}

.sec1 > a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.sec2 {
  position: relative;
  width: 70%;
  height: 100%;
  font-size: 1rem;
  color: white;
  display: flex;
  align-items: center;
}

.sec2 div {
  position: absolute;
  right: 3rem;
}
