.three {
  color: white;
  position: absolute;
  top: 0;
  left: 200%;
  width: 100%;
  height: 100%;
}

.project-wrapper {
  width: 100%;
  height: 95%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project {
  position: relative;
  height: 55%;
  width: 4%;
  transition: width 0.2s ease-in-out;
  background-size: cover;
  padding: 1rem;
}

.project-title {
  width: max-content;
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;
  left: 0;
  bottom: 0%;
  transform-origin: 0 0;
  transform: translate(0%, 50%) rotate(-90deg);
  text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3), 1px -1px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out;
}

.project-description {
  position: relative;
  font-size: 1.2rem;
  display: none;
  text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3), 1px -1px 5px rgba(0, 0, 0, 0.3);
}

@keyframes show {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
