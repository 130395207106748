.pr-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  margin-top: 10vh;
  padding: 5rem;
}

.pr-row1 {
  position: relative;
  width: 70%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  overflow-x: hidden;
}

.pr-col1 {
  position: relative;
  width: 60%;
}

.pr-col2 {
  position: relative;
  width: 35%;
  overflow-x: hidden;
}

.pr-title-image {
  width: 100%;
  z-index: -1;
  animation: movein 1s ease-in-out;
}

.pr-title {
  font-size: 5rem;
  font-weight: 700;
  width: 100%;
  letter-spacing: -0.2rem;
  text-transform: uppercase;
  animation: movein 0.8s ease-in-out;
}

.pr-desc-title {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
  animation: movein 0.9s ease-in-out;
}

.pr-desc {
  font-size: 2rem;
  font-weight: 300;
  animation: movein 1s ease-in-out;
}

.pr-link {
  width: 100%;
  animation: movein 1.1s ease-in-out !important;
}

.pr-row2 {
  width: 70%;
  font-size: 1.2rem;
  text-align: justify;
  padding-top: 2rem;
  column-count: 2;
  column-gap: 3rem;
  line-height: 1.7rem;
  border-top: white solid 1px;
}

.pr-row2::before {
  content: '';
  width: 50%;
  background-color: #141414;
  position: absolute;
  bottom: 0;
  left: 0%;
  animation: reveal 1.7s ease-in-out;
}

.pr-row2::after {
  content: '';
  width: 50%;
  background-color: #141414;
  position: absolute;
  bottom: 0;
  right: 0%;
  animation: reveal 2s ease-in-out;
}

.pr-container * {
  position: relative;
}

@keyframes movein {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes reveal {
  0% {
    height: 100%;
  }

  40% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.gallery {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 300;
  padding-top: 2rem;
}

.gallery > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 1rem;
}

.gallery img {
  width: 100%;
  padding-bottom: 1rem;
  border: 1px solid grey;
}
