.four {
  color: white;
  position: absolute;
  top: 0;
  left: 300%;
  width: 100%;
  height: 100%;
}

.contact-wrapper {
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact1,
.contact2 {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 3rem;
  font-weight: 700;
  row-gap: 5%;
}

.contact1 {
  padding-left: 15%;
  padding-right: 5%;
}

.contact2 {
  padding-left: 5%;
  padding-right: 15%;
}

.contact-links {
  font-size: 2rem;
  font-weight: 300;
}

.links {
  color: white;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-size: 2rem;
  font-weight: 300;
}

.links:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: white;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.2s linear;
}

.links:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.link-arrow {
  width: 1rem;
  filter: invert(1);
  transform: rotate(-90deg);
  margin-left: 0.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 300;
  height: 100%;
}

#name,
#email {
  height: 2rem;
  width: 50%;
  cursor: text;
  font-family: 'Roboto';
  font-size: 1rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: none;
}

#message {
  height: 40%;
  cursor: text;
  font-family: 'Roboto';
  font-size: 1rem;
  margin-bottom: 1rem;
}

.submit {
  width: fit-content;
  padding: 0.7rem 2rem;
  border: none;
  border-radius: 5rem;
  color: #141414;
  background-color: #ff5454;
  font-weight: 700;
  cursor: pointer;
  font-size: 1rem;
}

.submit:hover {
  opacity: 0.8;
}

.GIT {
  position: absolute;
  top: 15%;
  right: 0%;
  font-size: 5rem;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 2px;
}

#git2 {
  transform: translate(0, 100%);
}
