.doggo-div {
  position: absolute;
  width: 400%;
  height: 20vh;
  bottom: 0%;
  cursor: none;
  background-image: url('../media/doggo/ground.png');
  background-repeat: repeat-x;
  background-position: bottom left;
  background-size: auto 2.7rem;
}

.doggo {
  width: 6rem;
  height: 5rem;
  position: absolute;
  bottom: 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  transition: left 1s linear;
  transform: translateX(50%) scaleX(1);
  background-image: url('../media/doggo/0.png');
  cursor: pointer;
}

@keyframes bg {
  0% {
    background-image: url('../media/doggo/1.png');
  }
  50% {
    background-image: url('../media/doggo/2.png');
  }
  100% {
    background-image: url('../media/doggo/1.png');
  }
}

@keyframes ogbg {
  100% {
    background-image: url('../media/doggo/0.png');
  }
}

.ground {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  z-index: -1;
}

.bone {
  position: absolute;
  visibility: hidden;
  width: 2rem;
  transform: translate(-50%, -50%);
}
